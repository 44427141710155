import React from "react"

import Layout from "@src/components/Layout"
import Row from "@src/layouts/V2/components/Row/Row"
import ImageRow from "@src/layouts/V2/Features/components/ImageRow/ImageRow"
import Button from "@src/components/Button/Button"
import getAppUrl from "@src/utils/getAppUrl"
import DotBG from "@src/layouts/V2/components/DotBG/DotBG"
import CalloutWithDemoForm from "@src/components/CalloutWithDemoForm/CalloutWithDemoForm"
import Column from "@src/layouts/V2/components/Column/Column"
import Splash from "@src/layouts/V2/Features/components/Splash"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import HeaderImage from "@src/icons/manufacturing_header.png"
import Visualize from "@src/icons/manufacturing_visualize.png"
import Productivity from "@src/icons/manufacturing_productivity.png"
import Solutions from "@src/icons/manufacturing_solution.png"
import Progress from "@src/icons/manufacturing_progress.png"
import Training from "@src/icons/manufacturing_training.png"
import Reports from "@src/icons/manufacturing_reports.png"
import OG from "@src/icons/og/OG_Manufacturing.jpg"
import * as styles from "./CanonicManufacturing.module.scss"

const CanonicManufacturing = () => {
  const appUrl = getAppUrl.hook()
  const breakpoints = useBreakpoint()

  return (
    <Layout
      title="Streamline Your Manufacturing Operations with Low-Code"
      description="Low-code platforms boost efficiency, quality control, and customer experience while reducing costs and technical expertise, driving business success. "
      url="https://canonic.dev/industries/manufacturing"
      ogImage={OG}
      className={styles.manufacturing}
    >
      <Splash
        title={"Streamline Your Manufacturing Operations with Low-Code"}
        subtitle={
          "Low-code platforms boost efficiency, quality control, and customer experience while reducing costs and technical expertise, driving business success."
        }
        illustration={<img src={HeaderImage} />}
        ctas={[
          <a href={appUrl} target="_blank" rel="noopener noreferrer">
            <Button isPrimary>Try for free</Button>
          </a>,
          <a href={"#get-a-demo"}>
            <Button
              borderless
              marginLeft
              className={styles.manufacturingCtaOutline}
            >
              Get a Demo
            </Button>
          </a>,
        ]}
        reverseOnMobile
      />
      <Row>
        <div className={styles.manufacturingSolution}>
          <h2>A Low-Code Solution to Manufacturing Industry Challenges</h2>
          <p>
            Struggling to keep up with the manual operations in the
            manufacturing industry? You're not alone. The need to reduce costs,
            improve efficiency, and meet changing market demands can be
            overwhelming.
          </p>
          <p>
            With Canonic, you can simplify these development processes, automate
            tasks, and build custom applications with fewer resources. This
            means reduced costs, improved efficiency, and better quality
            control. Plus, Canonic also enables you to quickly respond to
            changing market demands, giving you the agility and responsiveness
            you need to stay ahead of the competition.
          </p>
        </div>
      </Row>
      <section>
        <Row className={styles.manufacturingProcess}>
          <h2>Agile Solution for optimizing Manufacturing processes</h2>
          <ImageRow
            title={"Connect and Visualize Data from Multiple Sources"}
            subtitle={
              "Analyze production, inventory, supply chain, and sales data using multiple data sources and dashboards. Visualize data in real-time and make prompt informed decisions with ease."
            }
            screenshot={Visualize}
            borderless
            className={styles.manufacturingProcessSources}
          />
          <ImageRow
            title="Boost Productivity with Workflow Automation"
            subtitle="Customizable workflows that automate repetitive tasks such as data entry, report generation, etc. This saves time and allows manufacturers to focus on critical tasks like forecasting and planning."
            screenshot={Productivity}
            reverse
            borderless
            className={styles.manufacturingProcessBoost}
          />
          <ImageRow
            title={"Tailor-Made Solutions for Your Business"}
            subtitle={
              "Build custom tools and applications that precisely fit your business needs, such as inventory management systems, quality control dashboards, and supply chain optimization tools. Our user-friendly low-code platform simplifies the process and eliminates the need for extensive coding skills."
            }
            screenshot={Solutions}
            borderless
            className={styles.manufacturingTailorMade}
          />
        </Row>
      </section>

      <section>
        <Row>
          <h1 className={styles.manufacturingCardsTitle}>
            Solve Your Manufacturing Challenges with Low-Code Custom Solutions.
          </h1>
          <div className={styles.manufacturingCards}>
            <Column.Wrap>
              <Column
                title="Dashboard Progress Tracking"
                icon={Progress}
                description={
                  "Create custom KPI/OKR dashboards with Canonic to monitor production processes and measure performance with ease. Stay ahead of your goals and make informed decisions quickly with real-time data and visualizations."
                }
              />
              <Column
                title="Tailored Learning Solutions"
                icon={Training}
                description={
                  "Develop custom training and development apps with low code. Create interactive learning experiences and programs tailored to your needs. Elevate your workforce's skills and enhance your operations with ease."
                }
              />
              <Column
                title="Custom Production Reports"
                icon={Reports}
                description={
                  "Create custom production reports that provide a detailed analysis of operations including. With real-time data and customized visualizations, manufacturers can identify potential bottlenecks and make data-driven decisions."
                }
              />
            </Column.Wrap>
          </div>
        </Row>
      </section>

      <CalloutWithDemoForm
        title="Unlock the ease of customized tools and applications for your manufacturing challenges with Canonic"
        originPage="Canonic for Manufacturing"
        id="get-a-demo"
        className={styles.manufacturingCallout}
      />
    </Layout>
  )
}

export default CanonicManufacturing
